import React from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"
import GlobalStyle from "@/styles/GlobalStyles"
import { Typography } from "@/styles/textstyles"
import { Title, Paragraph } from '@/components/TextComponents/textStyles'
import { Icon } from '@/components/Icon'

const Contact = ({ data }) => {
  const textData = {
    headline: data.directus?.TextGlobals.contact_headline ?? '',
    promptHeadline: data.directus?.TextGlobals.contact_prompt_headline ?? '',
  }

  const contactData = {
    phoneNumber: data.directus.clinic[0].phone_number ?? '',
    email: data.directus.clinic[0].email ?? '',
    address: data.directus.clinic[0].address ?? '',
    info: data.directus.clinic[0].info ?? ''
  }

  return (
    <main>
      <GlobalStyle />
      <Title as="h4" level="4" margin="0 0 26px">{textData.headline}</Title>
      {!!contactData.phoneNumber && <ContactItem>
        <img src='/Phone@2x.png' style={{ width: 20, height: 20, marginRight: 16}} />
        <Paragraph level="2" isSmall><a href={"tel:" + contactData.phoneNumber}>{contactData.phoneNumber}</a></Paragraph>
      </ContactItem>}
      {!!contactData.email && <ContactItem>
        <img src='/Mail@2x.png' style={{ width: 20, height: 20, marginRight: 16}} />
        <Paragraph level="2" isSmall><a href={"mailto:" + contactData.email}>{contactData.email}</a></Paragraph>
      </ContactItem>}
      {!!contactData.address && <ContactItem>
        <img src='/Location@2x.png' style={{ width: 20, height: 20, marginRight: 16}} />
        <Paragraph level="2" isSmall><a href={"hopemaprequest:" + contactData.address}>{contactData.address}</a></Paragraph>
      </ContactItem>}
      <ContactInfoField dangerouslySetInnerHTML={{__html: contactData.info}} />
      {/* <Title as="h4" level="4" margin="30px 0">{textData.promptHeadline}</Title> */}
    </main>
  )
}

const ContactItem = styled.div`
  display: flex;
  margin: 4px 0 16px;
`
const ContactInfoField = styled.div`
  margin-Top: 24px;
  line-height: 12px;
  padding-left: 36px;
  p {
    ${Typography.paragraphsAndCaptions.paragraphSmall1};
  }
  .uppercase {
    font-size: 12px;
    font-weight: bold;
  }
`

export const query = graphql`
  query Contact($clinic: String) {
    directus {
      TextGlobals {
        contact_headline
        contact_prompt_headline
      }
      clinic(filter: {clinic_id: {_eq: $clinic}}) {
        clinic_id
        phone_number
        email
        address
        info
      }
    }
  }
`

export default Contact
