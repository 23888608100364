import { createGlobalStyle } from "styled-components"
import { ColorPaletteMain as Colors } from "./ColorPalette"
import fonts from '@/fonts/fonts'

const GlobalStyle = createGlobalStyle`
  ${fonts}

  body {
    margin: 0;
    padding-top: 48px;
    color: ${Colors.primaryColors.ink6};
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    background-color: ${Colors.neutralColors.blackAndWhite.white0};
  }

  main {
    max-width: 480px;
    position: relative;
    padding: 48px 24px;
  }

  .gatsby-image-wrapper {
    position: relative;
    z-index: 0;
  }

  a {
    all: unset;
  }

  @media (min-width: 481px) {
    main {
      margin: 48px auto;
      padding-bottom: 12px;
      padding-top: 48px;
      background: white;
      border-radius: 24px;
      box-shadow: 0px 0px 30px rgba(0,0,0,.1);
      overflow: hidden;
      transform: translateZ(0);
    }
  }
`

export default GlobalStyle
