export const ColorPaletteMain = {
  primaryColors: {
    ink0: "#EAEBF4",
    ink1: "#C3C6D7",
    ink2: "#9DA1BB",
    ink3: "#767C9D",
    ink4: "#4E5682",
    ink5: "#252F66",
    ink6: "#1F2755"
  },
  accentColors: {
    blue4: "#00ACE5"
  },
  gradients: {
    blueGreen: "linear-gradient(45deg, #00ACE5 0%, #C3FDED 100%)",
    indigo: "linear-gradient(45deg, #858DBB 0%, #CED1E4 100%)",
    pink: "linear-gradient(45deg, #A72868 0%, #EB86B9 100%)",
    red: "linear-gradient(45deg, #96082C 0%, #FE6E7E 100%)",
    orange: "linear-gradient(45deg, #E37952 0%, #F4C9BA 100%)"
  },
  neutralColors: {
    blackAndWhite: {
      black0: "#000000",
      white0: "#FFFFFF",
      white1: "#FEFEFE",
    },
    grays: {
      gray0: "#E0E0E0",
      gray1: "#C1C1C1",
      gray2: "#A1A2A3",
      gray3: "#828384",
      gray4: "#636465",
      gray5: "#4A4B4C",
      gray6: "#323233"
    }
  },
  backgrounds: {
    grays: {
      background0: "#F8F8F8",
      background1: "#F2F2F2",
      background2: "#EBEBEB",
      background3: "#E5E5E5",
      background4: "#DFDFDF"
    },
    blues: {
      background0: "#F4FAFD",
      background1: "#E9F7FC",
      background2: "#DFF3FA",
      background3: "#D4F0F9",
      background4: "#CAECF8"
    },
    inks: {
      background0: "#FAFAFC",
      background1: "#F6F6FA",
      background2: "#F1F2F7",
      background3: "#EDEEF5",
      background4: "#E9EAF3"
    }
  }
}

export const ColorPaletteTertiaryColors = {
  greens: {
    green0: "#DBFEF4",
    green1: "#C3FDED",
    green2: "#A7D9CB",
    green3: "#8BB5A9",
    green4: "#6F9187",
    green5: "#546C66",
    green6: "#384844"
  },
  aquas: {
    aqua0: "#D8E9ED",
    aqua1: "#B2D3DB",
    aqua2: "#8BBDC9",
    aqua3: "#65A7B7",
    aqua4: "#3E91A5",
    aqua5: "#2F6D7C",
    aqua6: "#1F4953"
  },
  blues: {
    blue0: "#CCEEFA",
    blue1: "#99DEF5",
    blue2: "#66CDED",
    blue3: "#33BDEA",
    blue4: "#00ACE5",
    blue5: "#0081AC",
    blue6: "#005673"
  },
  purples: {
    purple0: "#ECE3F7",
    purple1: "#D9C6EF",
    purple2: "#C7AAE8",
    purple3: "#B48DE0",
    purple4: "#A171D8",
    purple5: "#7955A2",
    purple6: "#51396C"
  },
  wines: {
    wine0: "#EACED5",
    wine1: "#D59CAB",
    wine2: "#C06B80",
    wine3: "#AB3956",
    wine4: "#96082C",
    wine5: "#710621",
    wine6: "#4B0416"
  },
  pinks: {
    pink0: "#F7E3EE",
    pink1: "#EFC6DD",
    pink2: "#E8AACB",
    pink3: "#E08DBA",
    pink4: "#D871A9",
    pink5: "#A2557F",
    pink6: "#6C3955"
  },
  magentas: {
    magenta0: "#F8D7E8",
    magenta1: "#F2AED0",
    magenta2: "#EB86B9",
    magenta3: "#E55DA1",
    magenta4: "#DE358A",
    magenta5: "#A72868",
    magenta6: "#6F1B45"
  },
  corals: {
    coral0: "#FFE2E5",
    coral1: "#FFC5CB",
    coral2: "#FEA8B2",
    coral3: "#FE8B98",
    coral4: "#FE6E7E",
    coral5: "#BF535F",
    coral6: "#7F373F"
  },
  reds: {
    red0: "#FDDCDC",
    red1: "#FBB9B9",
    red2: "#F99595",
    red3: "#F77272",
    red4: "#F54F4F",
    red5: "#B83B3B",
    red6: "#7B2828"
  },
  skinTones: {
    skinTone0: "#F9E4DC",
    skinTone1: "#F4C9BA",
    skinTone2: "#EEAF97",
    skinTone3: "#E99475",
    skinTone4: "#E37952",
    skinTone5: "#AA5B3E",
    skinTone6: "#723D29"
  },
  beige: {
    beige0: "#F5EBEB",
    beige1: "#EBD6D6",
    beige2: "#E2C2C2",
    beige3: "#D8ADAD",
    beige4: "#CE9999",
    beige5: "#9B7373",
    beige6: "#674D4D"
  },
  indigos: {
    indigo0: "#E7E8F1",
    indigo1: "#CED1E4",
    indigo2: "#B6BBD6",
    indigo3: "#9DA4C9",
    indigo4: "#858DBB",
    indigo5: "#646A8C",
    indigo6: "#43475E"
  }
}