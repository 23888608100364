import styled from 'styled-components'
import { Typography } from '@/styles/textstyles'
import { ColorPaletteMain as Colors } from '@/styles/ColorPalette'

export const Title = styled.div<{
  level: 1 | 2 | 3 | 4 | 5 | 6
  margin?: string
}>`
  margin: ${props => props.margin ?? "0px"};
  ${props => Typography.headers[`h${props.level}`]};
`

export const SubTitle = styled.div<{
  level: 1 | 2
  isSmall?: boolean
  primary?: boolean
  margin?: string
}>`
  ${props => !!props.isSmall ?
    Typography.subtitles[`subtitleSmall${props.level}`] :
    Typography.subtitles[`subtitle${props.level}`]
  };
  color: ${props => !!props.primary ? Colors.accentColors.blue4 : ''};
  margin: ${props => !!props.margin ? props.margin : "0"};
`

export const Label = styled.div<{
  level: 1 | 2
  isSmall?: boolean
}>`
  ${props => !!props.isSmall ?
    Typography.labels[`labelSmall${props.level}`] :
    Typography.labels[`label${props.level}`]
  };
`

export const Caption = styled.div<{
  level: 1 | 2 | 3
  margin?: string
}>`
  margin: ${props => props.margin ?? "0"}
  ${props => Typography.paragraphsAndCaptions[`caption${props.level}`]};
`

export const Paragraph = styled.p<{
  level: 1 | 2 | 3
  margin?: string
  isSmall?: boolean
}>`
  ${props => !!props.isSmall ?
    Typography.paragraphsAndCaptions[`paragraphSmall${props.level}`] :
    Typography.paragraphsAndCaptions[`paragraph${props.level}`]
  };
  margin: ${props => props.margin ?? "0"}
`

export const DividerSubTitle = styled.div<{
    level: 1 | 2
    isSmall?: boolean
  }>`
  ${props => !!props.isSmall ?
    Typography.labels[`subtitleSmall${props.level}`] :
    Typography.subtitles[`subtitle${props.level}`]
  };
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  color: ${Colors.primaryColors.ink1};

  &::before,
  &::after {
    background: ${Colors.primaryColors.ink0};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 10px;
    margin-left: -50%;
  }

  &:after {
    left: 10px;
    margin-right: -50%;
  }
`
