
const fonts = `
  /* nunito-sans-200 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/nunito-sans-v5-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
       url('/fonts/nunito-sans-v5-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v5-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v5-latin-200.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v5-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v5-latin-200.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/nunito-sans-v4-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Light'), local('NunitoSans-Light'),
       url('/fonts/nunito-sans-v4-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v4-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v4-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/nunito-sans-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
       url('/fonts/nunito-sans-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v4-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-600 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/nunito-sans-v4-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
       url('/fonts/nunito-sans-v4-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v4-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v4-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/nunito-sans-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
       url('/fonts/nunito-sans-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v4-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-800 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/nunito-sans-v4-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
       url('/fonts/nunito-sans-v4-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v4-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-800.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v4-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-900 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/nunito-sans-v4-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Black'), local('NunitoSans-Black'),
       url('/fonts/nunito-sans-v4-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/nunito-sans-v4-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-900.woff') format('woff'), /* Modern Browsers */
       url('/fonts/nunito-sans-v4-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/nunito-sans-v4-latin-900.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
`

export default fonts
