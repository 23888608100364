export const FontFaces = {
  primary: "'Nunito Sans', 'Helvetica', 'sans-serif'"
}

export const FontWeights = {
  thin: "100",
  extraLight: "200",
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  extraBold: "800",
  black: "900"
}

export const Typography = {
  headers: {
    h1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraLight,
      "font-size": "84px",
      "line-height": "88px",
      "letter-spacing": "-1.5px",
    },
    h2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraLight,
      "font-size": "60px",
      "line-height": "64px",
      "letter-spacing": "-1px",
    },
    h3: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraLight,
      "font-size": "48px",
      "line-height": "52px",
      "letter-spacing": "-0.5px",
    },
    h4: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraLight,
      "font-size": "34px",
      "line-height": "36px",
      "letter-spacing": "-0.5px",
    },
    h5: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraLight,
      "font-size": "24px",
      "line-height": "28px",
      "letter-spacing": "-0.2px",
    },
    h6: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.regular,
      "font-size": "20px",
      "line-height": "24px",
      "letter-spacing": "0px",
    }
  },
  subtitles: {
    subtitle1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.semiBold,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "0.6px",
      "text-transform": "uppercase",
    },
    subtitle2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "0.6px",
      "text-transform": "uppercase",
    },
    subtitleSmall1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.semiBold,
      "font-size": "10px",
      "line-height": "12px",
      "letter-spacing": "0.8px",
      "text-transform": "uppercase",
    },
    subtitleSmall2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "10px",
      "line-height": "12px",
      "letter-spacing": "0.8px",
      "text-transform": "uppercase",
    }
  },
  paragraphsAndCaptions: {
    paragraph1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.regular,
      "font-size": "16px",
      "line-height": "24px",
      "letter-spacing": "-0.2px"
    },
    paragraph2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.bold,
      "font-size": "16px",
      "line-height": "24px",
      "letter-spacing": "0px"
    },
    paragraph3: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "16px",
      "line-height": "24px",
      "letter-spacing": "0px"
    },
    paragraphSmall1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.regular,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "-0.1px"
    },
    paragraphSmall2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.bold,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "0.1px"
    },
    paragraphSmall3: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "0.1px"
    },
    caption1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.regular,
      "font-size": "12px",
      "line-height": "16px",
      "letter-spacing": "-0.1px"
    },
    caption2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.bold,
      "font-size": "12px",
      "line-height": "16px",
      "letter-spacing": "0.1px"
    },
    caption3: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "12px",
      "line-height": "16px",
      "letter-spacing": "0.1px"
    }
  },
  labels: {
    label1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.semiBold,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "-0.1px"
    },
    label2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "14px",
      "line-height": "20px",
      "letter-spacing": "0.1px"
    },
    labelSmall1: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.semiBold,
      "font-size": "10px",
      "line-height": "12px",
      "letter-spacing": "0px"
    },
    labelSmall2: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "10px",
      "line-height": "12px",
      "letter-spacing": "0px"
    }
  },
  actions: {
    buttonText: {
      "font-family": FontFaces.primary,
      "font-weight": FontWeights.extraBold,
      "font-size": "16px",
      "line-height": "auto",
      "letter-spacing": "-0.1px"
    }
  }
}